import { Section } from "@carbon/react";
import React from "react";
import './HeroBanner.scss'

export const HeroBanner = ({ children, variant, ...rest }) => {
    const primaryGradientContainer = <div style={{
        backgroundColor: '#040E35',
        position: 'absolute',
        zIndex: '-1',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
    }}>
        
        <svg
        className="spark-gradient-primary-left"
    style={{
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 0,
        minBlockSize: '100%',

    }}
    xmlns="http://www.w3.org/2000/svg" width="1014" height="168" viewBox="0 0 1014 168" fill="none">
    <path d="M-22 -2.20435H1014L791.099 219.796H-22V-2.20435Z" fill="url(#paint0_radial_6981_27491)"/>
    <defs>
      <radialGradient id="paint0_radial_6981_27491" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-71.4584 -77.7186) rotate(11.7578) scale(915.296 4308.75)">
        <stop stop-color="#16CFB1"/>
        <stop offset="0.4" stop-color="#3569B8"/>
        <stop offset="1" stop-color="#5400C0"/>
      </radialGradient>
    </defs>
    </svg>
        <svg
        className="spark-gradient-primary-right"
    style={{
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 0,
        minBlockSize: '100%',
        transform: 'scaleY(1.2)'
    }}
    xmlns="http://www.w3.org/2000/svg" width="495" height="168" viewBox="0 0 495 168" fill="none">
    <path d="M594 219.796H0L222.671 -2.20435H594V219.796Z" fill="url(#paint0_linear_6981_27492)"/>
    <defs>
      <linearGradient id="paint0_linear_6981_27492" x1="942.601" y1="518.038" x2="84.0228" y2="-136.665" gradientUnits="userSpaceOnUse">
        <stop stop-color="#7000FF"/>
        <stop offset="1" stop-color="#040E35"/>
      </linearGradient>
    </defs>
    </svg>
    </div>
    const secondaryGradientCheck = variant === 'secondary' ? { background: 'linear-gradient(160deg, #7000FF 8.99%, #040E35 73.45%)' } : null;
    return <Section 
        style={{
            ...secondaryGradientCheck,
            padding: 'var(--cds-spark-theme-spacing-07)',
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
        }} {...rest}>
        {children}
        {variant === 'primary' && primaryGradientContainer}
        <style>
            {`
                .spark-gradient-primary-left {
                    transform: scaleY(1.2);
                }
                .spark-gradient-primary-mobile {
                    display: none;
                }
                @media (max-width: 50.5rem) {
                    .spark-gradient-primary-right {
                        display: none;
                    }
                    .spark-gradient-primary-left {
                        transform: scaleY(2);
                    }
                    .spark-heading-size-m {
                        font-size: 24px;
                        line-height: 1.4;
                    }
                }
                @media (max-width: 24.75rem) {
                    .spark-gradient-primary-left {
                        transform: scaleY(7);
                    }
                }
            `}
        </style>
    </Section>;
};