import './TiberHeader.scss';
import React from 'react';
import logoIcon from '../../resources/images/tribber_trade_mark.svg';
import { Header, Link, OverflowMenu, OverflowMenuItem } from '@carbon/react';

const HelpCircleIcon = () => <i className="spark-icon spark-icon-light spark-icon-help-circle help-circle-icon"></i>
const ProfileIcon = () => 
    <i
       className="spark-icon spark-icon-user spark-icon-light help-circle-icon"
    />
const SupportLink = () => { return (<div className='help-icon-content'><i className="spark-icon spark-icon-light spark-icon-external-link support-link" />&nbsp;<p className='help-icon-content-text'>Support</p></div>) }
const LogOutAction = () => { return (<div className='help-icon-content'>&nbsp;<p className='help-icon-content-text'>Logout</p></div>) }
const GlobalHeader = (props) => {

    return (
        <>
            <div id="recode50header" style={{ display: 'none' }} className="no-animate"></div>
            <Header role="banner" className="global-header">

                <div className='left-global-header'>
                    <span className="brand-slot-global-header">
                        <img className="logo" src={logoIcon} alt="Intel Tiber Edge"></img>
                    </span>
                    <span className="separator-global-header">
                        <p className="separator-text-global-header">|</p>
                    </span>
                    <span className="project-name-global-header">
                        <Link className="home-link" target="_self" href="https://edgesoftwarecatalog.intel.com/">
                            <p className="project-name-text-header">Software Catalog</p>
                        </Link>
                    </span>
                </div>

                {/* Collapsible menu items */}

                <>

                    <div className='center-header-menu-button'>
                        <div className='button-menu'>
                            {/* <div className='button'>
                                    <div className='button-content'>
                                        <p className='button-content-text'>Skip to main content</p>
                                    </div>
                                 </div> */}
                        </div>
                    </div>
                    <div className='right-global-header'>
                        <div className='profile'>
                            <div className='profile-button'>
                                {/* <div className='icon-user'>
                                    <i onClick={handleLogin} className="spark-icon spark-icon-user spark-icon-light" />
                                </div> */}
                            </div>
                        </div>
                        <div className='action-button-slot'>
                            <div className='action-button hide-toggletip' data-theme="dark" data-color-scheme="tiber">
                                <OverflowMenu className='help-icon-menuitem-section' aria-label="" focusTrap={false} flipped={true} align="right" renderIcon={HelpCircleIcon}>
                                    <OverflowMenuItem focusTrap={false} href="https://community.intel.com/t5/Intel-Edge-Software-Hub/bd-p/edge-software-hub" className='help-icon-menuitem' target="_blank" itemText={<SupportLink />}></OverflowMenuItem>
                                </OverflowMenu>
                                <OverflowMenu className='profile-menuitem-section' aria-label="" focusTrap={false} flipped={true} align="right" renderIcon={ProfileIcon}>
                                    <OverflowMenuItem focusTrap={false}  onClick={()=>{window.intel.signinsimplify.signOut()}} className='help-icon-menuitem' itemText={<LogOutAction />}></OverflowMenuItem>
                                </OverflowMenu>
                            </div>
                        </div>
                    </div>
                </>
            </Header>
        </>
    );
};

export default GlobalHeader;