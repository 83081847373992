import React, { Component } from "react";
import styles from "./DownloadRecipe.module.css";
import { connect } from "react-redux";
import { downloadRecipe, requestcnda, UpdateServiceRequest } from "../../actions/EIAction";
import EdgeInsightDownload from "../../components/EdgeInsightDownload/EdgeInsightDownload";
/* import PipDownloader from "../../components/PipDownloader/PipDownloader"; */
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import DownloadIcon from "../../resources/images/download_icon.svg";
import { generateBreadcrumb } from "../../components/Common/common"

class DownloadRecipe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceId: "",
      resourceName: "",
      resourceDesc: "",
      resourceImage: "",
      resourceRedirectionLink: ""
    }
  }
  async componentDidMount() {
    if (this.props.history.action === "PUSH") {
      const { EIReducer } = this.props;
      this.props.UpdateServiceRequest();
      const selectedLanguage = EIReducer.selectedLanguage;
      const EITitle = EIReducer.EITitle[selectedLanguage];
      let data = {};
      let licenseType = this.props.EIReducer.licenceType;
      let isCNDALicenseApproved = this.props.EIReducer.isCNDALicenseApproved;
      if (licenseType.toLowerCase() === 'cnda' && isCNDALicenseApproved === false) {
        data.recipeId = this.props.EIReducer.recipeId;
        data.ircId = this.props.EIReducer.ircProductId;
        this.props.requestcnda(data);
      } else {
        data.components = [];
        data.images = [];
        data.helmIds = [];
        let selectedIngredients;
        let downloadType = this.props.EIReducer.downloadType;
        selectedIngredients = this.props.EIReducer.selectedIngredientsXML;
        let selectedRecipe = this.props.EIReducer.selectedRecipeVersion;
        let recipeClass = this.props.EIReducer.recipeClass;
        let selectedOS = this.props.EIReducer.selectedOS;
        let eId = this.props.EIReducer.eId;
        let productKey = this.props.EIReducer.productKey;
        let otherFiles = this.props.EIReducer.otherFiles;
        let ircProductId = this.props.EIReducer.ircProductId;
        let selectedFilterOption = this.props.EIReducer.selectedFilterOptions;
        if (this.props.EIReducer.gettingStartedGuide) {
          data.gettingStartedGuide = this.props.EIReducer.gettingStartedGuide;
        }
        if (selectedFilterOption.length) {
          data.selectedFilterOption = selectedFilterOption;
        }
        data.downloadType = downloadType;
        if (selectedIngredients) {
			  let selectedIngredientsId = [];
          for (var i = 0; i < selectedIngredients.length; i++) {
            if (selectedIngredients[i].type === undefined || selectedIngredients[i].type === "ingredient") {
              if (downloadType === "custom") {
                selectedIngredientsId.push(selectedIngredients[i].id);
                if (
                  selectedIngredients[i].dependencies &&
                  selectedIngredients[i].dependencies.length
                ) {
                  selectedIngredients[i].dependencies.map((e) => {
                    selectedIngredientsId.push(e);
                  });
                }
              } else {
                data.components.push(selectedIngredients[i].id);
              }
            } else if (selectedIngredients[i].type === "container") {
              data.images.push(selectedIngredients[i].id);
            } else if (selectedIngredients[i].type === "helmchart") {
              data.helmIds.push(selectedIngredients[i].id);
            }
          }
          if (downloadType === "custom") {
            let uniqueSelectedIngredientsId = [...new Set(selectedIngredientsId)];
            data.components.push(...uniqueSelectedIngredientsId);
          }
        }

        data.zipLabel = this.props.EIReducer.zipLabel || this.props.EIReducer.recipeURL;
        data.recipeClass = recipeClass;
        data.otherFiles = otherFiles;
        data.ircProductId = ircProductId;
        if (selectedRecipe) {
          data.recipeId = selectedRecipe;
          data.recipeName = EITitle;
        }
        data.selectedUseCase =
          selectedRecipe === "5efb3ad87e3933002a6b41b2"
            ? this.props.EIReducer.selectedUseCase
            : "";
        if (selectedOS) {
          data.selectedOS = selectedOS;
        }
        if (eId !== "" && productKey !== "") {
          data.eId = eId;
          data.productKey = productKey;
        } else if (productKey !== "" || productKey !== null) {
          data.productKey = productKey;
        }
        data.isUserOpted = this.props.EIReducer.isUserOptIn;
        this.props.downloadRecipe(data, recipeClass, "downloadrecipe");
      }
      let supportedResources = this.props.EIReducer.supportedResources;
      let randomResource = supportedResources[Math.floor(Math.random() * supportedResources.length)];
      if (randomResource && randomResource.id) {
        this.setState({
          resourceId: randomResource.id,
          resourceName: randomResource.text,
          resourceDesc: randomResource.description,
          resourceImage: randomResource.imageUrl,
          resourceRedirectionLink: randomResource.titleUrl
        })
        this.generateBreadcrumbFn();
      }
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", this.onBackButtonEvent);

    } else {
      let baseURL = this.props.EIReducer.baseURL;
      this.props.history.push(baseURL);
    }
  }

  generateBreadcrumbFn = () => {
    if (window.intel && window.intel.breadcrumbFromJSON) {
      const selectedLanguage = this.props.EIReducer.selectedLanguage;
      let EITitle = "";
      if (this.props.EIReducer.EITitle) {
        EITitle = this.props.EIReducer.EITitle[selectedLanguage];
        if (EITitle === undefined || EITitle === "") {
          EITitle = this.props.EIReducer.EITitle["en"];
        }
      }
      let data = {
        EITitle,
        pageName: "download",
        history: this.props.history,
        homeURL: this.props.EIReducer.baseURL
      }
      generateBreadcrumb(data);
    } else {
      setTimeout(() => {
        this.generateBreadcrumbFn();
      }, 1000);

    }
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackButtonEvent)
  }

  onBackButtonEvent = () => {
    window.location.reload();
  }

  render() {
    const { t } = this.props;
    let selectedLanguage = this.props.EIReducer.selectedLanguage;
    let recipeName = this.props.EIReducer.EITitle[selectedLanguage] ? this.props.EIReducer.EITitle[selectedLanguage] : this.props.EIReducer.EITitle.en;
    let resourceName = this.state.resourceName[selectedLanguage] ? this.state.resourceName[selectedLanguage] : this.state.resourceName["en"];
    let resourceDesc = this.state.resourceDesc[selectedLanguage] ? this.state.resourceDesc[selectedLanguage] : this.state.resourceDesc["en"];
    let recommendedForYouCLick = resourceName + "_click";
    return (
      <div className={styles.sectionWrapper}>
        <div className={styles.DownloadHeaderWrapper}>
          <div className={styles.headerWrapper}><div className={styles.iconWrapper}><img src={DownloadIcon} alt="Download Icon" /></div><div className={styles.headerText}>{this.props.EIReducer.PackagingPercentage === 100 ? t("DOWNLOADED") : t("DOWNLOADING")} {recipeName}</div></div>
          <div style={{ width: this.props.EIReducer.PackagingPercentage === "" ? 0 : this.props.EIReducer.PackagingPercentage + "%", minWidth: "10%", display: "flex", marginLeft: 60, paddingRight: 58 }}>
            <div style={{ backgroundColor: "#004A86", width: "8%", height: 28 }}></div>
            <div style={{ backgroundColor: "#0068B5", width: "15%", height: 28 }}></div>
            <div style={{ backgroundColor: "#00A3F6", width: "31%", height: 28 }}></div>
            <div className={styles.ProgressBarSection4}>{this.props.EIReducer.PackagingPercentage}%</div>
          </div>
        </div>
        <div className={styles.main}>
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <EdgeInsightDownload />
            </div>
            <div className="col-lg-5 col-md-5">
              <div className={styles.downloadInfoContainer}>
                <div className={styles.downloadRecommendation}>
                  <span className={styles.downloadInstruction}>
                    {t("RECOMMENDED_FOR_YOU")}
                  </span>
                  <img
                    alt={resourceName}
                    //style={{ width: "350px", height: "210px" }}
                    className={styles.downloadimage}
                    src={this.state.resourceImage} />
                  <h1 className={styles.recommendedConfigTitle}>
                    <a
                      data-wap_ref={recommendedForYouCLick}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.resourceRedirectionLink}
                    >
                      {resourceName}
                    </a>
                  </h1>
                  <p className={styles.resorceDescription}>
                    {resourceDesc}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  EIReducer: state.EIReducer,
});
export default connect(mapStateToProps, { downloadRecipe, requestcnda, UpdateServiceRequest })(withTranslation()(withRouter(DownloadRecipe)));
