import React from "react";
import { connect } from "react-redux";
import {
  UpdateEnsightContent,
  UpdateRecipeClass,
  checkEntitlementAndGenerateUniqueKey,
  UpdateSelectedIngredients,
  UpdateUserSelections,
  HandleLoader,
  UpdateDownloadType,
  checkEntitlementAndGenerateUniqueKeyNoRedirect,
  UpdateGtsBlockerResponse,
  VerifyEntitlement
} from "./../../actions/EIAction";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Overlay, Popover } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
// import { Button } from "react-bootstrap";
import styles from "./GridSelection.module.scss";
import _ from "lodash";
import RecommendedImage from "../../resources/images/recommended.svg";
import DocLinkImg from "../../resources/images/ic_link.svg";
import Chevron from "../../resources/images/chevron.svg";
import checkboxIcon from "../../resources/images/checkbox_unselected.svg";
import checkboxIconSelected from "../../resources/images/checkbox_selected.svg";
import EIIncludedComponents from "../EIIncludedComponents/EIIncludedComponents";
import { MuiThemeProvider } from "@material-ui/core/styles";
import InfoOutlinedIcon from "../../resources/images/info_circle.svg";
import CloseIcon from "@material-ui/icons/Close";
import LicenseAgreement from "../../pages/LicenseAgreement/LicenseAgreement";
import RequestAccessDialog from "../header/RequestAccessDialog";
import { Button } from '@carbon/react';
import './GridSelection.module.scss';

let selectedRecipeType = '';

class MyPopover extends React.Component {
  render(){
    var left = 400;

    return (
      <Popover {...this.props} positionLeft={left}>
        { this.props.children }
      </Popover>
    )
  }
}

class GridSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optInForSubscription: true,
      selectedRecipeVersion: "",
      selectedOSVersion: "",
      selecteduseCaseVersion: "",
      show: false,
      selectedDownloadOption: "",
      displayIngredients: false,
      filteredIngredientsonCollapse: [],
      displayCopyBtn: false,
      displayTileSection: true,
      moduleSectionTop: "auto",
      gridSelectionHeight: "auto",
      moduleSectionHeight: "auto",
      consumptionBoxHeight: "auto",
      selectedFilterOption: [],
      selectedFilterOptions: {},
      openConsumptionPopover: false,
      popoverBodyHeight: "auto",
      showInfo: false,
      infoTarget: "",
      selectionTypeHelpText: "140 chars Description of what a target system is Description of what a target system is Description of what a target system is Description ",
      selectionTypeLabel: "",
      openRequestAccessDialog: this.props.EIReducer.gtsBlockerResponse
    };
    this.moduleSectionWrapper = React.createRef();
    this.consumptionBoxHeight = React.createRef();
    this.GridHeightState = "";
  }

  handleOptInSelection = () => {
    this.setState({ optInForSubscription: !this.state.optInForSubscription });
  };

  handleChange = async (id, value, text) => {
    if (id === "recipeVersionForm") {
      const selectedRecipeData = this.props.EIReducer.recipes.find((e) => {
        return e.id === value
      });
      selectedRecipeType = selectedRecipeData.recipeTypeName[this.props.EIReducer.selectedLanguage];
      this.props.UpdateRecipeClass(selectedRecipeType);
      console.log("licenseType", this.props.EIReducer.licenceType)
    }
    let currentFilter = document.getElementById(value);
    if (!currentFilter.classList.contains(styles.paperDisabled)) {
      let selectedOS = this.props.EIReducer.selectedOS;
      let downloadDetails = this.props.EIReducer.EIDownloadList;
      let selectedRecipeVersion = "";
      let recipeType = selectedRecipeType;
      let recipes = this.props.EIReducer.recipes;
      let getIngredients = recipes.findIndex(
        (item) => item.id === this.state.selectedRecipeVersion
      );
      let ingredients = recipes[getIngredients]?.ingredients;
      if (recipeType === "Online Distribution") {
        ingredients = recipes[getIngredients]?.distributionIds;
      }
      let data = {
        selectedOS: selectedOS,
        selectedOSName: this.props.EIReducer.selectedOSName,
        selectedUseCase: this.props.EIReducer.selectedUseCase,
        isUpdateRecipeVersion: false,
        updateRecipe: {},
        isUpdateFilterSelection: false,
        updateFilterOptions: {},
      };
      let filterContent = this.props.EIReducer.filterContent;
      let filterTags = [];
      let selectedFilterOption = [];
      let selectedFilterOptions = {};
      if (this.props.EIReducer.selectedFilterOption.length) {
        selectedFilterOption = this.props.EIReducer.selectedFilterOption;
      }
      if (this.props.EIReducer.selectedFilterOptions.length) {
        selectedFilterOptions = this.props.EIReducer.selectedFilterOptions;
      }
      if (value && id) {
        if (id === "recipeVersionForm") {
          if (this.state.selectedRecipeVersion !== value) {
            selectedFilterOption = [];
            selectedFilterOptions = {};
            selectedRecipeVersion = value;
            let recipes = this.props.EIReducer.recipes;
            getIngredients = recipes.findIndex(
              (item) => item.id === selectedRecipeVersion
            );
            this.setState({
              selectedRecipeVersion: value,
            });
            data.isUpdateRecipeVersion = true;

            ingredients = recipes[getIngredients].ingredients;
            if (recipeType === "Online Distribution") {
              ingredients = recipes[getIngredients].distributionIds;
            }
            let dependencyList = [];
            ingredients.forEach((ingredient, index) => {
              let dependencies = ingredient.dependencies;
              if (dependencies && dependencies.length) {
                dependencies.forEach((dependency) => {
                  let depend = {
                    dependerId: ingredient.id,
                    dependeeId: dependency,
                  };
                  dependencyList.push(depend);
                });
              }
              if (recipeType === "Online Distribution") {
                filterContent = recipes[getIngredients].filterTags;
                let filterOptions = filterContent.map(function (
                  filterTag,
                  index
                ) {
                  let arr = {};
                  let id = filterTag._id;
                  let tags = filterTag.tags;
                  tags.forEach((element) => {
                    if (ingredient.filterTags.includes(element.id)) {
                      arr[id] = element.id;
                    }
                  });
                  return arr;
                });
                filterOptions = filterOptions.filter(
                  (value) => Object.keys(value).length !== 0
                );
                ingredients[index].filterOptions = filterOptions;
              }
            });
            data.updateRecipe.dependency = dependencyList;
            var customisedIngedients = _.mapValues(
              _.groupBy(ingredients, "ingredientType"),
              (clist) =>
                clist.map((ingredients) =>
                  _.omit(ingredients, "ingredientType")
                )
            );
            filterContent = recipes[getIngredients].filterTags;
            let twmpFilterContent = [];
            twmpFilterContent = filterContent.filter((filterTag, index) => {
              if (filterTag._id && filterTag.tags.length) {
                let filters = {};
                filters.id = filterTag._id;
                filters.tagIds = [];
                this.setState({
                  [filters.id]: "",
                });
                let tags = filterTag.tags;
                tags.forEach((element) => {
                  filters.tagIds.push(element.id);
                });
                filterTags.push(filters);
                return true;
              }
              return false;
            });
            if (recipes[getIngredients].isCustomizable === false) {
              this.setState({
                selectedDownloadOption: "",
              });
            }
            if (recipeType !== "Online Distribution") {
              let filterContent = recipes[getIngredients].filterTags;
              let filterTags = [];
              let twmpFilterContent = [];
              twmpFilterContent = filterContent.filter((filterTag, index) => {
                if (filterTag._id && filterTag.tags.length) {
                  let filters = {};
                  filters.id = filterTag._id;
                  filters.tagIds = [];
                  let tags = filterTag.tags;
                  tags.forEach((element) => {
                    filters.tagIds.push(element.id);
                  });
                  filterTags.push(filters);
                  return true;
                }
                return false;
              });
              filterContent = twmpFilterContent;
              data.filterContent = filterContent;
              if (filterTags.length) {
                selectedFilterOption = filterTags.map(function (x) {
                  return x.tagIds[0];
                });
                selectedFilterOptions = filterTags.map(function (x) {
                  let id = x.id;
                  return {
                    [id]: x.tagIds[0],
                  };
                });
              }
              let selectedFilters = {};
              filterContent.forEach((filterOption, index) => {
                filterOption.tags.forEach((filterTags) => {
                  selectedFilterOption.forEach((id) => {
                    if (id === filterTags.id) {
                      selectedFilters[filterOption._id] = id;
                    }
                  });
                });
              });
              this.setState({
                ...selectedFilters,
              });
            }

            this.setState({
              selectedFilterOptions: selectedFilterOptions,
              selectedFilterOption: selectedFilterOption,
            });
            filterContent = twmpFilterContent;
            data.updateRecipe.isCustomizable =
              recipes[getIngredients].isCustomizable;
            data.updateRecipe.ingredients = ingredients;
            data.updateRecipe.customisedIngedients = customisedIngedients;
            data.updateRecipe.selectedRecipeID = selectedRecipeVersion;
            data.updateRecipe.selectedRecipeName =
              recipes[getIngredients].version;
            data.updateRecipe.learnMoreLink =
              recipes[getIngredients].learnMoreLink;
            data.updateRecipe.EIDesc = recipes[getIngredients].desc;
            data.updateRecipe.documentationLink =
              recipes[getIngredients].documentationLink;
            data.updateRecipe.otherFiles = recipes[getIngredients].otherFiles;
            data.updateRecipe.gettingStartedGuide =
              recipes[getIngredients].gettingStartedGuide;
            data.updateRecipe.zipLabel = recipes[getIngredients].zipLabel;
            data.updateRecipe.licenceType = recipes[getIngredients].licenceType;
            data.updateRecipe.filterContent = filterContent;
            data.updateRecipe.filterTags = filterTags;
            data.updateRecipe.selectedFilterOption = selectedFilterOption;
            data.updateRecipe.selectedFilterOptions = selectedFilterOptions;
            data.updateRecipe.hardwareAndSystemRequirementLinks =
              recipes[getIngredients].hardwareAndSystemRequirementLinks;
            data.updateRecipe.ircProductId = this.props.EIReducer.recipes.find((e) => {
                return e.id === value
              }).ircProductId
            downloadDetails = recipes[getIngredients].ingredients;
            let allOSList = this.props.EIReducer.completeOSVersions;
            let supportedOsIds = recipes[getIngredients].supportedOsIds;
            let defaultOS = recipes[getIngredients].defaultOs;
            let supportedOSList = [];
            let supportedResourcesId = recipes[getIngredients].resource.concat(
              recipes[getIngredients].resourceIds
            );
            let supportedResources = [];
            let allResources = this.props.EIReducer.resources;
            allResources.forEach((resource) => {
              supportedResourcesId.forEach((resourceId) => {
                if (resourceId === resource.id) {
                  supportedResources.push(resource);
                }
              });
            });
            data.updateRecipe.supportedResources = supportedResources;
            allOSList.forEach((element) => {
              supportedOsIds.forEach((id) => {
                if (element.id === id) {
                  supportedOSList.push(element);
                }
              });
            });

            if (supportedOsIds.indexOf(defaultOS) !== -1) {
              selectedOS = defaultOS;
              data.selectedOS = defaultOS;
              this.setState({
                selectedOSVersion: defaultOS,
              });

            }
            data.osList = supportedOSList;
            data.updateRecipe.osList = supportedOSList;
            supportedOSList.map((e) => {
              if (e.id === defaultOS) {
                data.selectedOSName = e.name["en"];
              }
            })
          }
        } else if (id === "targetOSForm") {
          //let selectedOSText = target.options[target.selectedIndex].text;
          selectedOS = value;
          data.selectedOSName = text;
          data.selectedOS = selectedOS;
          this.setState({
            selectedOSVersion: value,
          });
        } else {
          if (
            recipeType === "Online Distribution" &&
            this.state[id] === value
          ) {
            this.setState({
              [id]: "",
            });
          } else {
            this.setState({
              [id]: value,
            });
          }

          let filterOptionState = this.state.selectedFilter;
          //filterOptionState[0] = "";
          this.setState({ selectedFilter: filterOptionState });
          if (this.props.EIReducer.selectedFilterOption) {
            selectedFilterOption = this.props.EIReducer.selectedFilterOption;
          }
          if (this.props.EIReducer.selectedFilterOptions) {
            selectedFilterOptions = this.props.EIReducer.selectedFilterOptions;
          }
          let filterTags = this.props.EIReducer.filterTags;
          var selectedFilter = filterTags.find((item) => item.id === id);
          if (selectedFilterOption.length && this.state[id] !== value) {
            let tmpSelectedFilterOption = selectedFilterOption;
            selectedFilterOption.forEach((selectedFilterId, index) => {
              if (selectedFilter.tagIds.indexOf(selectedFilterId) >= 0) {
                tmpSelectedFilterOption.splice(index, 1);
              }
            });
            selectedFilterOption = tmpSelectedFilterOption;
          } else {
            if( recipeType === "Online Distribution"){
              var index = selectedFilterOption.indexOf(value);
              if (index !== -1) {
                selectedFilterOption.splice(index, 1);
              }
            }            
          }

          if (value !== this.state[id]) {
            selectedFilterOption.push(value);
          }
          //let filterContent = ;
          let filterOptions = filterContent.map(function (filterTag) {
            let arr = {};
            let id = filterTag._id;
            let tags = filterTag.tags;
            tags.forEach((element) => {
              if (selectedFilterOption.includes(element.id)) {
                arr[id] = element.id;
              }
            });
            return arr;
          });
          selectedFilterOptions = filterOptions.filter(
            (value) => Object.keys(value).length !== 0
          );
          this.setState({
            selectedFilterOptions: selectedFilterOptions,
            selectedFilterOption: selectedFilterOption,
          });
        }
        this.setState({
          selectedFilterOptions: selectedFilterOptions,
          selectedFilterOption: selectedFilterOption,
        });
        data.isUpdateFilterSelection = true;
        data.updateFilterOptions.selectedFilterOption = selectedFilterOption;
        data.updateFilterOptions.selectedFilterOptions = selectedFilterOptions;
        let query = {};
        let XMLquery = {};
        if (recipeType !== "Online Distribution")
          if (this.state.selectedDownloadOption === "download" || this.state.selectedDownloadOption === "") {
            query = {
              osIds: selectedOS,
              isRecommended: true,
              hideDisplay: false
            };
            XMLquery = {
              osIds: selectedOS,
              isRecommended: true,
              hideInstall: false,
            }
          } else {
            query = {
              osIds: selectedOS,
              hideDisplay: false
            };
            XMLquery = {
              osIds: selectedOS,
              hideDisplay: false
            }
          }

        if (selectedFilterOption.length) {
          query.filterTags = selectedFilterOption;
          XMLquery.filterTags = selectedFilterOption;
        }
        let filteredIngredients = downloadDetails.filter((item) => this.filterIngredientList(item, query, recipeType));
        data.selectedIngredientsXML = downloadDetails.filter((item) => this.filterIngredientList(item, XMLquery, recipeType));
        data.selectedIngredients = filteredIngredients;
        this.setState({
          filteredIngredientsonCollapse: filteredIngredients,
        });
        let UpdateResponse = this.props.UpdateUserSelections(data);
        if(id === 'recipeVersionForm' && data.updateRecipe.licenceType.toLowerCase() === 'cnda'){
          // const selectedRecipeData = this.props.EIReducer.recipes.find((e) => {
          //   return e.id === value
          // });
          await this.props.VerifyEntitlement(data.updateRecipe.ircProductId, "checkCNDALicense")
        }
        if (recipeType === "Online Distribution") {
          setTimeout(() => {
            filterContent.forEach((filterOption) => {
              let disableFilterList = document.getElementById(filterOption._id);
              for (var i = 0; i < disableFilterList.children.length; i++) {
                var child = disableFilterList.children[i];
                let tempSelectedFilterOption = selectedFilterOption.slice();
                tempSelectedFilterOption.push(child.id);
                let flag = true;
                ingredients?.forEach((element) => {
                  if (
                    tempSelectedFilterOption
                      .sort()
                      .every((r) => element.filterTags.sort().includes(r))
                  ) {
                    flag = false;
                  }
                });
                if (flag) {
                  child.classList.add(styles.paperDisabled);
                } else {
                  child.classList.remove(styles.paperDisabled);
                }
              }
            });
          }, 200);
        }
        if (UpdateResponse) {
          this.setState({
            moduleSectionTop: "auto",
          });
          setTimeout(() => {
            this.calculateHeight();
          }, 200);
        }
      }
    }
  };

  componentDidMount() {
    selectedRecipeType = this.props.EIReducer.recipeClass;
    this.setState({
      filteredIngredientsonCollapse: this.props.EIReducer.selectedIngredients,
    });
    let osVersions = this.props.EIReducer.osVersions;
    let selectedOSversion = "";
    let selectedOS = this.props.EIReducer.selectedOS;
    let selectedDistribution = this.props.EIReducer.selectedDistribution;
    if (document.getElementById("gridSelectionWrapper")) {
      this.setState({
        moduleSectionTop:
          document.getElementById("gridSelectionWrapper").offsetHeight + "px",
        gridSelectionHeight:
          document.getElementById("gridSelectionWrapper").offsetHeight + "px",
      });
    }
    osVersions.forEach((osVersion) => {
      if (selectedOS === osVersion.id) {
        selectedOSversion = osVersion.id;
      }
    });
    if (selectedDistribution === "default") {
      this.setState({ selectedDownloadOption: "download" });
    } else if (selectedDistribution === "custom") {
      this.setState({ selectedDownloadOption: "customDownload" });
    }

    if (selectedOSversion) {
      this.setState({ selectedOSVersion: selectedOSversion });
    }
    let selectedRecipeVersion = this.props.EIReducer.selectedRecipeVersion;
    const recipeVersions = this.props.EIReducer.recipeVersions;
    console.log("reducer ", this.props.EIReducer)
    if (!selectedRecipeVersion) {
      selectedRecipeVersion = recipeVersions[0].id;
    }
    if (recipeVersions.length) {
      this.setState({ selectedRecipeVersion: selectedRecipeVersion });
    }
    const euCountryList = [
      "AT",
      "BE",
      "BG",
      "HR",
      "CY",
      "CZ",
      "DK",
      "EE",
      "FI",
      "FR",
      "DE",
      "GR",
      "HU",
      "IE",
      "IT",
      "LV",
      "LT",
      "LU",
      "MT",
      "NL",
      "PL",
      "PT",
      "RO",
      "SK",
      "SI",
      "ES",
      "SE",
    ];
    const userCountry = this.props.EIReducer.session.country;
    if (euCountryList.indexOf(userCountry) >= 0) {
      this.setState({ optInForSubscription: false });
    }
    let filteredContent = this.props.EIReducer.filterContent;
    let selectedFilter = [];
    let selectedFilterOption = this.props.EIReducer.selectedFilterOption;
    let selectedFilterOptions = {};
    const selectedLanguage = this.props.EIReducer.selectedLanguage;
    filteredContent.forEach((filterOption, index) => {
      filterOption.tags.forEach((filterTags) => {
        selectedFilterOption.forEach((id) => {
          if (id === filterTags.id) {
            selectedFilter[index] =
              filterTags.name[selectedLanguage] !== undefined
                ? filterTags.name[selectedLanguage]
                : filterTags.name["en"];
            selectedFilterOptions[filterOption._id] = id;
          }
        });
      });
    });
    this.setState({
      ...selectedFilterOptions,
    });
    this.calculateHeight();
    let query = {};
    let XMLquery = {};
    let recipeType = selectedRecipeType;
    if (recipeType !== "Online Distribution") {
      if (this.state.selectedDownloadOption === "download" || this.state.selectedDownloadOption === "") {
        query = {
          osIds: selectedOS,
          isRecommended: true,
          hideDisplay: false
        };
        XMLquery = {
          osIds: selectedOS,
          isRecommended: true,
          hideInstall: false,
        }
      } else {
        query = {
          osIds: selectedOS,
          hideDisplay: false
        };
        XMLquery = {
          osIds: selectedOS,
          hideDisplay: false
        }
      }
    }
    let downloadDetails = this.props.EIReducer.EIDownloadList;
    if (selectedFilterOption.length) {
      XMLquery.filterTags = selectedFilterOption;
      query.filterTags = selectedFilterOption;
    }
    let selectedIngredients = downloadDetails.filter((item) => this.filterIngredientList(item, query, recipeType));
    let selectedIngredientsXML = downloadDetails.filter((item) => this.filterIngredientList(item, XMLquery, recipeType));
    let data = {selectedIngredients,selectedIngredientsXML}
    this.props.UpdateSelectedIngredients(data);

    if ( recipeType === "Online Distribution") {
      recipeVersions.map((recipeVersion, index) => {
        if (selectedRecipeVersion === recipeVersion.id) {
          // this.handleChange(
          //   "recipeVersionForm",
          //   recipeVersion.id,
          //   recipeVersion.label
          // );
        }
      })

      // osVersions.map((osVersion, index) => {
      //   if (selectedOSversion === osVersion.id) {
      //     this.handleChange(
      //       "targetOSForm",
      //       osVersion.id,
      //       osVersion.name["en"]
      //     );
      //   }
      // })
      
      const filterContent = this.props.EIReducer.filterContent
      if (filterContent[0] && filterContent[0].tags && filterContent[0].tags.length == 1){
        this.handleChange(
          filterContent[0]['_id'], 
          filterContent[0].tags[0]['id'], 
          filterContent[0].tags[0]['name']['en']
        )
      }
    }

    // let isSingleEntry = true;
    // filteredContent.map((filterOption) => {
    //   if (filterOption.tags.length > 1) {
    //     isSingleEntry = false;
    //   }
    // })

    // if (isSingleEntry) {
    //   filteredContent.map((filterOption) => {
    //     if (filterOption.tags.length === 1) {
    //       filterOption.tags.map((filterTags, index) => {
    //         if (index === 0) {
    //           this.handleChange(
    //             filterOption._id,
    //             filterTags.id,
    //             filterTags.name["en"]
    //           );
    //           setTimeout(() => {
    //             const element = document.getElementById(filterTags.id);
    //             element.classList.remove(styles.paperDisabled);
    //           }, 1000);
    //         }
    //       })
    //     }
    //   })
    //   if (selectedRecipeType !== "Online Distribution") {
    //     this.setState({ selectedDownloadOption: "download" });
    //   }
    // }

    // this.props.EIReducer.recipes.forEach(recipe=>{
    //   if (recipe?.recipeTypeName?.en === "Online Distribution"){
    //     let data = {};
    //     data.components = [];
    //     data.images = [];
    //     data.helmIds = [];
    //     let selectedRecipe = recipe.id;
    //     let ircProductId = recipe.ircProductId;
    //     let selectedOSVersion = recipe.selectedOS;
    //     data.recipeURL = this.props.EIReducer.recipeURL;
    //     data.recipeClass = recipe?.recipeTypeName?.en;
    //       data.recipeId = selectedRecipe;
    //     data.ircProductId = ircProductId;
    //     data.downloadType = "";      
    //     data.isProdKeyRequired = recipe.productKey;
    //     this.props.checkEntitlementAndGenerateUniqueKeyNoRedirect(data)
    //   }
    // })
  }

  filterIngredientList = (item, query, recipeType) => {
    for (var key in query) {
      let queryVal = query[key];
      if (typeof (item[key]) === "boolean") {
        if (item[key] === undefined || item[key] !== query[key])
          return false;
      } else if (Array.isArray(queryVal)) {
        let flag = true;
        if (key === "filterTags") {
          if (
            selectedRecipeType === "Online Distribution" &&
            item.filterOptions.length !== query[key].length
          ) {
            flag = false;
            return false;
          }
        }
        if (queryVal.length === 0) {
          flag = false;
          return false;
        }
        for (var k in queryVal) {
          if (
            item[key].indexOf(queryVal[k].toString()) === -1 &&
            item[key] !== undefined
          ) {
            flag = false;
            return false;
          }
        }
        return flag;
      } else {
        if (item[key] === undefined || item[key].indexOf(query[key]) < 0)
          return false;
      }
    }
    if (
      selectedRecipeType === "Online Distribution" &&
      Object.keys(query).length === 0
    ) {
      return false;
    }
    return true;
  }

  closeRequestAccessDialog = () => {
    this.props.UpdateGtsBlockerResponse(false)
    this.setState({ openRequestAccessDialog: false });
  };

  calculateHeight = () => {
    let gridSelectionWrapperHeight = document.getElementById(
      "gridSelectionWrapper"
    ).offsetHeight;
    let moduleSectionWrapperHeight = 0;
    if (document.getElementById("moduleSectionWrapper")) {
      moduleSectionWrapperHeight = document.getElementById(
        "moduleSectionWrapper"
      ).offsetHeight;
    }
    this.setState({
      moduleSectionHeight:
        gridSelectionWrapperHeight + moduleSectionWrapperHeight,
      moduleSectionTop:
        document.getElementById("gridSelectionWrapper").offsetHeight + "px",
      gridSelectionHeight:
        document.getElementById("gridSelectionWrapper").offsetHeight + "px",
    });
  };
  componentDidUpdate(prevProps, prevState) {
    const hasAChanged =
      this.props.EIReducer.selectedIngredients !==
      prevProps.EIReducer.selectedIngredients;
    const isDisplayIngredients =
      this.state.selectedDownloadOption !== prevState.selectedDownloadOption;
    const filteredIngredientsonCollapse =
      this.state.filteredIngredientsonCollapse.length !==
      prevState.filteredIngredientsonCollapse.length;
    if (hasAChanged) {
      this.setState({
        filteredIngredientsonCollapse: this.props.EIReducer.selectedIngredients,
      });
    }

    if (
      isDisplayIngredients ||
      (this.props.EIReducer.recipeClass === "Online Distribution" &&
        filteredIngredientsonCollapse)
    ) {
      this.calculateHeight();
      if (this.moduleSectionWrapper && this.moduleSectionWrapper.current) {
        let documentationWrapperWidth = 292;
        let consumptionBoxHeight =
          this.moduleSectionWrapper.current.offsetWidth -
          (90 + documentationWrapperWidth);
        this.setState({
          consumptionBoxHeight,
        });
      }
    }
    let licenseType = this.props.EIReducer.licenceType;
    if (
      licenseType.toLowerCase() === "cnda" &&
      this.props.EIReducer.isCNDALicenseApproved !==
      prevProps.EIReducer.isCNDALicenseApproved
    ) {
      this.calculateHeight();
      this.setState({
        moduleSectionTop: "auto",
      });
      setTimeout(() => {
        this.setState({
          moduleSectionTop:
            document.getElementById("gridSelectionWrapper").offsetHeight + "px",
        });
      }, 200);
    }

    const filterContent = this.props.EIReducer.filterContent
      if (
        prevProps.EIReducer.filterContent[0]?.tags[0]['id'] !== this.props.EIReducer.filterContent[0]?.tags[0]['id'] && 
        filterContent[0] && filterContent[0].tags && 
        filterContent[0].tags.length == 1 && 
        this.props.EIReducer.recipeClass === "Online Distribution"){
        this.handleChange(
          filterContent[0]['_id'], 
          filterContent[0].tags[0]['id'], 
          filterContent[0].tags[0]['name']['en']
        )
      }
  }
  defaultDownload = () => {
    let selectedOS = this.props.EIReducer.selectedOS;
    let UpdateSelectedIngredients = {};
    let query = {
      osIds: selectedOS,
      isRecommended: true,
      hideDisplay: false
    };
    let XMLquery = {
      osIds: selectedOS,
      isRecommended: true,
      hideInstall: false,
    }
    let recipeType = selectedRecipeType;
    let selectedFilterOption = [];
    let downloadDetails = this.props.EIReducer.EIDownloadList;
    if (this.props.EIReducer.selectedFilterOption.length) {
      selectedFilterOption = this.props.EIReducer.selectedFilterOption;
      XMLquery.filterTags = selectedFilterOption;
    }
    if (selectedFilterOption.length) {
      query.filterTags = selectedFilterOption;
    }
    let selectedIngredients = downloadDetails.filter((item) => this.filterIngredientList(item, query, recipeType));
    let selectedIngredientsXML = downloadDetails.filter((item) => this.filterIngredientList(item, XMLquery, recipeType));
    UpdateSelectedIngredients.selectedIngredientsXML = selectedIngredientsXML;
    UpdateSelectedIngredients.selectedIngredients = selectedIngredients;

    selectedIngredients = selectedIngredients.filter((ingredients) => {
      if (ingredients.isRecommended === true) {
        return _.omit(ingredients, "ingredientType");
      } else {
        return false;
      }
    });
    if (selectedIngredients.length) {
      let data = {};
      data.components = [];
      data.images = [];
      data.helmIds = [];
      let selectedOSVersion = this.props.EIReducer.selectedOS;
      let selectedRecipe = this.props.EIReducer.selectedRecipeVersion;
      let ircProductId = this.props.EIReducer.ircProductId;
      let recipeClass = selectedRecipeType;
      let UIRoute = this.props.EIReducer.recipeURL;
      data.recipeURL = this.props.EIReducer.recipeURL;
      data.isUserOptIn = this.state.optInForSubscription;
      data.recipeClass = recipeClass;
      if (this.props.EIReducer.gettingStartedGuide) {
        data.gettingStartedGuide = this.props.EIReducer.gettingStartedGuide;
      }
      for (var i = 0; i < selectedIngredientsXML.length; i++) {
        if (
          selectedIngredientsXML[i].type === undefined ||
          selectedIngredientsXML[i].type === "ingredient"
        ) {
          data.components.push(selectedIngredientsXML[i].id);
        } else if (selectedIngredientsXML[i].type === "container") {
          data.images.push(selectedIngredientsXML[i].id);
        } else if (selectedIngredientsXML[i].type === "helmchart") {
          data.helmIds.push(selectedIngredientsXML[i].id);
        }
      }
      if (selectedRecipe) {
        data.recipeId = selectedRecipe;
        data.ircProductId = ircProductId;
        data.osId = selectedOSVersion;
        data.selectedFilterTag = this.props.EIReducer.selectedFilterOption
          .length
          ? this.props.EIReducer.selectedFilterOption
          : null;
        data.downloadType = "default";
	let recipes = this.props.EIReducer.recipes;
        let getIngredients = recipes.findIndex(
          (item) => item.id === this.props.EIReducer.selectedRecipeVersion
        );        
        data.isProdKeyRequired = recipes[getIngredients].productKey;
      }
      window.wap_tms.aaTrack.do(
        "Components",
        "click: Download",
        UIRoute + "-download"
      );
      this.props.UpdateSelectedIngredients(UpdateSelectedIngredients);
      this.props.UpdateDownloadType("default");
      this.props.HandleLoader(true);
      this.props.checkEntitlementAndGenerateUniqueKey(
        data,
        "default",
        this.props.history
      );
    }
  };

  licenseAgreementForOnlineDistribution = ()=> {
    let data = {};
    data.components = [];
    data.images = [];
    data.helmIds = [];
    let selectedRecipe = this.props.EIReducer.selectedRecipeVersion;
    let ircProductId = this.props.EIReducer.ircProductId;
    let recipeClass = selectedRecipeType;
    let selectedOSVersion = this.props.EIReducer.selectedOS;
    data.recipeURL = this.props.EIReducer.recipeURL;
    data.isUserOptIn = this.state.optInForSubscription;
    data.recipeClass = recipeClass;
    if (selectedRecipe) {
      data.recipeId = selectedRecipe;
      data.ircProductId = ircProductId;
      data.osId = selectedOSVersion;
      data.selectedFilterTag = this.props.EIReducer.selectedFilterOption
        .length
        ? this.props.EIReducer.selectedFilterOption
        : null;
      data.downloadType = "default";
let recipes = this.props.EIReducer.recipes;
      let getIngredients = recipes.findIndex(
        (item) => item.id === this.props.EIReducer.selectedRecipeVersion
      );        
      data.isProdKeyRequired = recipes[getIngredients].productKey;
    }
    data.baseURL = this.props.EIReducer.baseURL
    this.props.HandleLoader(true);
    this.props.UpdateDownloadType("");
      this.props.checkEntitlementAndGenerateUniqueKey(
        data,
        "",
        this.props.history
      );
  }

  CustomizeDownload = () => {
    let selectedIngredients = this.props.EIReducer.selectedIngredients;
    if (selectedIngredients.length) {
      this.props.HandleLoader(true);
      let selectedOS = this.props.EIReducer.selectedOS;
      let downloadDetails = this.props.EIReducer.EIDownloadList;
      let query = {};
      let data = {};
      let recipeType = selectedRecipeType;
      let selectedFilterOption = [];
      let UIRoute = this.props.EIReducer.recipeURL;
      if (this.props.EIReducer.selectedFilterOption.length) {
        selectedFilterOption = this.props.EIReducer.selectedFilterOption;
      }
      query = { osIds: selectedOS, hideDisplay: false };
      if (selectedFilterOption.length) {
        query.filterTags = selectedFilterOption;
      }
      let filteredIngredients = downloadDetails.filter((item) => this.filterIngredientList(item, query, recipeType));
      data.selectedIngredientsXML = filteredIngredients;
      data.selectedIngredients = filteredIngredients;
      this.props.UpdateDownloadType("custom");
      this.props.UpdateSelectedIngredients(data);
      window.wap_tms.aaTrack.do(
        "Components",
        "click: customizeDownload",
        UIRoute + "-custom-download-start"
      );
      this.props.history.push("/customdownload");
    }
  };
  selectedDownloadOption = (id) => {
    let query = {};
    let XMLquery = {};
    let data = {};
    let selectedOS = this.props.EIReducer.selectedOS;
    if (id === "download") {
      query = {
        osIds: selectedOS,
        isRecommended: true,
        hideDisplay: false
      };
      XMLquery = {
        osIds: selectedOS,
        isRecommended: true,
        hideInstall: false,
      }
    } else {
      query = {
        osIds: selectedOS,
        hideDisplay: false
      };
      XMLquery = {
        osIds: selectedOS,
        hideDisplay: false
      }
    }
    let recipeType = selectedRecipeType;
    let selectedFilterOption = [];
    let downloadDetails = this.props.EIReducer.EIDownloadList;
    if (this.props.EIReducer.selectedFilterOption.length) {
      selectedFilterOption = this.props.EIReducer.selectedFilterOption;
      XMLquery.filterTags = selectedFilterOption;
    }
    if (selectedFilterOption.length) {
      query.filterTags = selectedFilterOption;
    }
    let selectedIngredients = downloadDetails.filter((item) => this.filterIngredientList(item, query, recipeType));
    let selectedIngredientsXML = downloadDetails.filter((item) => this.filterIngredientList(item, XMLquery, recipeType));
    data.selectedIngredientsXML = selectedIngredientsXML;
    data.selectedIngredients = selectedIngredients;
    this.props.UpdateSelectedIngredients(data);
    this.setState({
      selectedDownloadOption: id,
    });
  };
  getClickableLink = (link) => {
    return link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `http://${link}`;
  };
  displayIngredients = () => {
    let moduleSectionTop = this.state.gridSelectionHeight;

    if (this.state.displayIngredients === true) {
      this.setState({
        displayIngredients: false,
        //displayTileSection: true,
        moduleSectionTop: moduleSectionTop,
      });
      setTimeout(() => {
        let moduleSectionHeight = "auto";
        let gridSelectionWrapperHeight = document.getElementById(
          "gridSelectionWrapper"
        ).offsetHeight;
        if (document.getElementById("moduleSectionWrapper")) {
          moduleSectionHeight =
            gridSelectionWrapperHeight +
            document.getElementById("moduleSectionWrapper").offsetHeight;
        }
        this.setState({
          moduleSectionHeight: moduleSectionHeight,
        });
      }, 200);
    } else {
      let recipeType = selectedRecipeType;
      if (recipeType !== "Online Distribution") {
        // window.scrollBy(0, 500);
        this.setState({
          displayIngredients: true,
          //displayTileSection: false,
          moduleSectionTop: 0,
          popoverBodyHeight: document.getElementById(
            "moduleSectionWrapper"
          ).offsetHeight
        });
        setTimeout(() => {
          let moduleSectionHeight = "auto";
          if (document.getElementById("moduleSectionWrapper")) {
            moduleSectionHeight = document.getElementById(
              "moduleSectionWrapper"
            ).offsetHeight;
          }
          this.setState({
            moduleSectionHeight: moduleSectionHeight,
          });
        }, 200);
      }
    }
  };
  handleMouseOver = (e) => {
    this.setState({
      displayCopyBtn: true,
      openConsumptionPopover: true,
    });
  };
  handleMouseOut = (e) => {
    this.setState({
      displayCopyBtn: false,
      openConsumptionPopover: false,
    });
  };
  modulesHeight = () => {
    let RootHeight = this.state.moduleSectionHeight;
    let flag = false;
    let PrevGridHeight = this.GridHeightState;
    let GridHeight =
      document.getElementById("gridSection") &&
      document.getElementById("gridSection").offsetHeight;

    if (
      PrevGridHeight?.PrevGridHeight !== GridHeight &&
      PrevGridHeight > GridHeight
    ) {
      flag = true;
      this.GridHeightState = GridHeight;
    }
    if (this.state.displayIngredients) return 0;
    if (RootHeight === "auto") {
      return "64px";
    } else if (RootHeight > 0 && GridHeight > 0 && RootHeight !== "auto") {
      let finalValue = "";
      finalValue = Math.abs(GridHeight - 100);
      if (flag === true) {
        finalValue = finalValue + Math.abs(PrevGridHeight - GridHeight);
        return finalValue;
      }
      if (window.screen.availWidth >= 1920) return finalValue / 2;
      else return finalValue;
    }
  };
  handleClick = (e, text) => {
    this.setState({
      showInfo: true,
      infoTarget: e.target.parentElement,
      selectionTypeHelpText: text,
      selectionTypeLabel: e.target.name
    });
  }
  closeOverlay = () => {
    this.setState({
      showInfo: false,
    });
  }
  render() {
    const { t } = this.props;
    let recipeClassInColapse = selectedRecipeType;
    let recipeVersions = [];
    let downloadOptions = [];
    if (recipeClassInColapse !== "Online Distribution") {
      downloadOptions = [
        {
          buttonType: t("DOWNLOAD_RECOMMENDED_CONFIGURATION"),
          buttonId: "download",
        },
      ];
      const isCustomisedButtonRequired = this.props.EIReducer.isCustomizable;
      isCustomisedButtonRequired &&
        downloadOptions.push({
          buttonType: t("CUSTOMIZE_DOWNLOAD"),
          buttonId: "customDownload",
        });
    }
    let osVersions = [];
    const selectedLanguage = this.props.EIReducer.selectedLanguage;
    let recipeName = this.props.EIReducer.baseURL;
    let packageName = recipeName.toLowerCase().replace(/\//g, "");
    let filteredContent = this.props.EIReducer.filterContent;
    let recipeType = selectedRecipeType;
    let hardwareLink = this.props.EIReducer.hardwareAndSystemRequirementLinks;
    let learnMoreLink = this.props.EIReducer.learnMoreLink;
    let documentationLink = this.props.EIReducer.documentationLink;
    let licenseType = this.props.EIReducer.licenceType;
    let isCNDALicenseApproved = this.props.EIReducer.isCNDALicenseApproved;
    let isCNDAProduct = false;
    let TileSelectionVersionText =
      recipeClassInColapse !== "Online Distribution"
        ? t("VERSION") + " " + t("OR") + " " + t("TAG")
        : t("VERSION");
    let onlineDistroLicenseAccepted = this.props.EIReducer.onlineDistroLicenseAccepted
    if (licenseType.toLowerCase() === "cnda" && isCNDALicenseApproved === false) {
      isCNDAProduct = true;
    }
    let downloadBtnText = t("DOWNLOAD");
    if (this.state.selectedDownloadOption) {
      downloadBtnText = t("CUSTOMIZE");
      if (this.state.selectedDownloadOption === "download") {
        downloadBtnText = t("DOWNLOAD");
      }
    }
    if (this.props.EIReducer.recipeVersions.length) {
      recipeVersions = this.props.EIReducer.recipeVersions;
    }
    if (this.props.EIReducer.osVersions.length) {
      osVersions = this.props.EIReducer.osVersions;
    }
    return (
      <MuiThemeProvider>
        <div
          className={styles.root}
          style={{ height: this.state.moduleSectionHeight }}
        >
          <RequestAccessDialog
            openDialog={this.state.openRequestAccessDialog}
            closeRequestAccessDialog={this.closeRequestAccessDialog}
            CNDAServiceResponse={false}
            gtsBlockerResponse={this.props.EIReducer.gtsBlockerResponse}
            customMessage={this.props.EIReducer.gtsBlockerMsg}
          />
          {this.state.displayTileSection && (
            <div className="TileSectionWithTitle" id="gridSelectionWrapper">
              <div className={styles.downloadTextDiv}>
                {/* <img
                  src={RecommendedImage}
                  className={styles.RecommendedImage}
                  alt="Recommended Icon"
                /> */}
                <span className={styles.downloadText}>
                  {isCNDAProduct
                    ? t("OPTIONS_AVAILABLE_FOR_DOWNLOAD")
                    : t("SELECT_OPTIONS_BELOW_TO_DOWNLOAD")}
                </span>
                {/* <div className={styles.dottedLine} /> */}
              </div>
              <div className={styles.GridSection} id="gridSection">
                <div style={{ display: "flex" }}>
                  <div className={styles.selectionTitle}>
                    <span className={styles.TileSelectionTitleSpan}>
                      {TileSelectionVersionText}
                    </span>
                    <span
                      id="info_version"
                      ref={this.triggerRef}
                      style={{ marginRight: '15px' }}
                    >
                      <img
                        src={InfoOutlinedIcon}
                        alt="Info icon"
                        name={TileSelectionVersionText}
                        onClick={(event) => this.handleClick(event, "Select the Version/Tag name of the package")}
                        className={styles.infoIconStyle}
                      />    
                    </span>
                  </div>
                  <div className={styles.selectionContent}>
                    <Grid container>
                      {recipeVersions.map((recipeVersion, index) => (
                        <Tooltip
                          title={recipeVersion.label}
                          placement="top"
                          arrow
                        >
                          <Paper
                            onClick={() => {
                              this.handleChange(
                                "recipeVersionForm",
                                recipeVersion.id,
                                recipeVersion.label
                              );
                            }}
                            value={recipeVersion.id}
                            id={recipeVersion.id}
                            style={{
                              width: `calc(${100 / recipeVersions.length
                                }% - 8px`,
                              minWidth: `calc(${100 / 6}% - 8px`,
                            }}
                            className={
                              recipeVersion.id ===
                                this.state.selectedRecipeVersion
                                ? styles.paperSelected
                                : styles.paper
                            }
                          >
                            {recipeVersion.label}
                          </Paper>
                        </Tooltip>
                      ))}
                    </Grid>
                  </div>
                </div>
                {recipeType !== "Online Distribution" && (
                  <div style={{ display: "flex" }}>
                    <div className={styles.selectionTitle}>
                      <span className={styles.TileSelectionTitleSpan}>
                        {t("TARGET_SYSTEM")}
                      </span>
                      <span
                        id="info_target_system"
                        ref={this.triggerRef}
                        style={{ marginRight: '15px' }}
                      >
                        <img
                          src={InfoOutlinedIcon}
                          alt="Info icon"
                          name={t("TARGET_SYSTEM")}
                          onClick={(event) => this.handleClick(event, "Select the operating systems that are compatible with this package")}
                          className={styles.infoIconStyle}
                        />
                      </span>
                    </div>
                    <div className={styles.selectionContent}>
                      <Grid container>
                        {osVersions.map((osVersion, index) => (
                          <Tooltip
                            title={
                              osVersion.name[selectedLanguage] !== undefined
                                ? osVersion.name[selectedLanguage]
                                : osVersion.name["en"]
                            }
                            placement="top"
                            arrow
                          >
                            <Paper
                              onClick={() => {
                                this.handleChange(
                                  "targetOSForm",
                                  osVersion.id,
                                  osVersion.name["en"]
                                );
                              }}
                              value={osVersion.id}
                              id={osVersion.id}
                              className={
                                osVersion.id === this.state.selectedOSVersion
                                  ? styles.paperSelected
                                  : styles.paper
                              }
                              style={{
                                width: `calc(${100 / osVersions.length}% - 8px`,
                                minWidth: `calc(${100 / 6}% - 8px`,
                              }}
                            >
                              {osVersion.name[selectedLanguage] !== undefined
                                ? osVersion.name[selectedLanguage]
                                : osVersion.name["en"]}
                            </Paper>
                          </Tooltip>
                        ))}
                      </Grid>
                    </div>
                  </div>
                )}

                {filteredContent.map((filterOption) => (
                  <div style={{ display: "flex" }}>
                    <div className={styles.selectionTitle}>
                      <span className={styles.TileSelectionTitleSpan}>
                        {filterOption.tagName &&
                          (filterOption.tagName[selectedLanguage]
                            ? filterOption.tagName[selectedLanguage]
                            : filterOption.tagName["en"])}
                      </span>
                      {filterOption.helpText && (filterOption.helpText.en && (
                        <span
                          id={"info_" + filterOption._id}
                          ref={this.triggerRef}
                          style={{ marginRight: '15px' }}
                        >
                          <img
                            src={InfoOutlinedIcon}
                            alt="Info icon"
                            name={filterOption.tagName &&
                              (filterOption.tagName[selectedLanguage]
                                ? filterOption.tagName[selectedLanguage]
                                : filterOption.tagName["en"])}
                            onClick={(event) => this.handleClick(event, filterOption.helpText &&
                              (filterOption.helpText[selectedLanguage]
                                ? filterOption.helpText[selectedLanguage]
                                : filterOption.helpText["en"]))}
                            className={styles.infoIconStyle}
                          />
                        </span>))}
                    </div>
                    <div className={styles.selectionContent}>
                      <Grid
                        container
                        aria-label="Use cases"
                        data-filterId={filterOption._id}
                        id={filterOption._id}
                        name={filterOption._id}
                        value={this.state[filterOption._id]}
                        onChange={this.handleChange}
                      >
                        {filterOption.tags.map((filterTags) => (
                          <Tooltip
                            title={
                              filterTags.name[selectedLanguage] !== undefined
                                ? filterTags.name[selectedLanguage]
                                : filterTags.name["en"]
                            }
                            placement="top"
                            arrow
                          >
                            <Paper
                              onClick={() => {
                                this.handleChange(
                                  filterOption._id,
                                  filterTags.id,
                                  filterTags.name["en"]
                                );
                              }}
                              className={
                                filterTags.id === this.state[filterOption._id]
                                  ? styles.paperSelected
                                  : styles.paper
                              }
                              value={filterTags.id}
                              id={filterTags.id}
                              style={{
                                width: `calc(${100 / filterOption.tags.length
                                  }% - 8px`,
                                minWidth: `calc(${100 / 6}% - 8px`,
                              }}
                            >
                              {filterTags.name[selectedLanguage] !== undefined
                                ? filterTags.name[selectedLanguage]
                                : filterTags.name["en"]}
                            </Paper>
                          </Tooltip>
                        ))}
                      </Grid>
                    </div>
                  </div>
                ))}
                {recipeType !== "Online Distribution" &&
                  isCNDAProduct === false && (
                    <div style={{ display: "flex" }}>
                      <div className={styles.selectionTitle}>
                        <span>{t("DISTRIBUTION")}</span>
                        <span
                          id={"info_distribution"}
                          ref={this.triggerRef}
                          style={{ marginRight: '15px' }}
                        >
                          <img
                            src={InfoOutlinedIcon}
                            alt="Info icon"
                            name={t("DISTRIBUTION")}
                            onClick={(event) => this.handleClick(event, "Select Distribution")}
                            className={styles.infoIconStyle}
                          />
                        </span>
                      </div>
                      <div className={styles.selectionContent}>
                        <Grid container>
                          {downloadOptions.map((options) => (
                            <Paper
                              id={options.buttonId}
                              onClick={() => {
                                this.selectedDownloadOption(options.buttonId);
                              }}
                              className={
                                options.buttonId ===
                                  this.state.selectedDownloadOption
                                  ? styles.paperSelected
                                  : downloadOptions.length === 1
                                    ? styles.paperSelected
                                    : styles.paper
                              }
                              value={options.buttonText}
                              style={{
                                width:
                                  downloadOptions.length === 1
                                    ? `calc(${100}% - 8px`
                                    : `calc(${100 / 2}% - 8px`,
                              }}
                            >
                              {options.buttonType}
                              {/* {options === t("DOWNLOAD")
                                    ? t("DOWNLOAD_RECOMMENDED_CONFIGURATION")
                                    : options} */}
                            </Paper>
                          ))}
                        </Grid>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
          {/* {recipeClassInColapse === "Online Distribution" &&
              this.state.filteredIngredientsonCollapse.length === 1 && 
                !onlineDistroLicenseAccepted[this.state.selectedRecipeVersion] &&
                (<LicenseAgreement></LicenseAgreement>)} */}
          {(this.state.selectedDownloadOption !== "" ||
            downloadOptions.length === 1 ||
            (recipeClassInColapse === "Online Distribution" &&
              this.state.filteredIngredientsonCollapse.length === 1)) &&
            isCNDAProduct === false && (
              <div
                className={styles.moduleSectionWrapper}
                ref={this.moduleSectionWrapper}
                id="moduleSectionWrapper"
                style={{
                  top:
                    recipeClassInColapse === "Online Distribution"
                      ? "auto"
                      : this.state.moduleSectionTop,
                }}
              >
                <div
                  className={styles.ModulesAvailable}
                  style={{
                    marginTop: this.state.displayIngredients ? 0 : 32,
                  }}
                >
                 
                  {recipeType !== "Online Distribution" && (
                    <div className={styles.ModulesAvailableText}>
                      <span className={styles.ModulesAvailableTextSpan}>
                        {t("MODULES_AVAILABLE_WITH_YOUR_SELECTION")}
                      </span>
                      <span
                        className={styles.DownArrowImg}
                        onClick={this.displayIngredients}
                      >
                        <img
                          src={Chevron}
                          className="material-icons"
                          id={
                            this.state.displayIngredients
                              ? styles.ExpandIcon
                              : styles.CollapseIcon
                          }
                          alt="Chevron"
                        />
                      </span>
                      <div className={styles.VerticalLine} />
                    </div>
                  )}
                  {(hardwareLink || documentationLink) && (
                    <div className={styles.EIDocumentLinks}>
                      {learnMoreLink && (
                        <span className={styles.EIDocumentLinksText}>
                          {/* <img
                            src={DocLinkImg}
                            className={styles.DocLinkImg}
                            alt=""
                          /> */}
                          <i className={`spark-icon spark-icon-light spark-icon-external-link ${styles.DocLinkImg}`}/>
                          <a
                            className={styles.EIDocColor}
                            target="_blank"
                            rel="noreferrer"
                            href={this.getClickableLink(learnMoreLink)}
                          >
                            {t("LEARN_MORE")}
                          </a>
                        </span>
                      )}
                      {documentationLink && (
                        <span className={styles.EIDocumentLinksText}>
                          {/* <img
                            src={DocLinkImg}
                            className={styles.}
                            alt="Documentation link icon"
                          /> */}
                          <i className={`spark-icon spark-icon-light spark-icon-external-link ${styles.DocLinkImg}`}/>
                          <a
                            className={styles.EIDocColor}
                            href={this.getClickableLink(documentationLink)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("DOCUMENTATION")}
                          </a>
                        </span>
                      )}
                      {hardwareLink && (
                        <span className={styles.EIDocumentLinksText}>
                          {/* <img
                            src={DocLinkImg}
                            className={styles.DocLinkImg}
                            alt=""
                          /> */}
                          <i className={`spark-icon spark-icon-light spark-icon-external-link ${styles.DocLinkImg}`}/>
                          <a
                            className={styles.EIDocColor}
                            target="_blank"
                            rel="noreferrer"
                            href={this.getClickableLink(hardwareLink)}
                          >
                            {t("RECOMMENDED_HARDWARE")}
                          </a>
                        </span>
                      )}
                    </div>
                  )}
                 
                  {(this.state.selectedDownloadOption !== "" ||
                    downloadOptions.length === 1) && (
                      <div className={styles.btnWrapper}>
                        <Button
                          id="defaultDownload"
                          className={
                            this.props.EIReducer.selectedIngredients.length
                              ? styles.downloadBtn
                              : `${styles.buttonDisabled} ${styles.downloadBtn}`
                          }
                          data-component="wa_skip_track"
                          onClick={
                            this.state.selectedDownloadOption === "customDownload"
                              ? this.CustomizeDownload
                              : this.defaultDownload
                          }
                        >
                          {downloadBtnText}
                        </Button>
                      </div>
                    )}
                    {console.log("here",recipeClassInColapse, this.state.filteredIngredientsonCollapse)}
                  {recipeClassInColapse === "Online Distribution" &&
                    this.state.filteredIngredientsonCollapse.length === 1 && 
                    ( ((onlineDistroLicenseAccepted[this.state.selectedRecipeVersion]) || (recipeClassInColapse === "Online Distribution" && isCNDALicenseApproved === true)) ?
                    this.state.filteredIngredientsonCollapse.map(
                      (downloadItemList, index) => {
                        return(
                        <div
                          className={styles.ODTextBox}
                          id="ODTextBox"
                          key={index}
                          name="Component List"
                          onMouseEnter={this.handleMouseOver}
                          onMouseLeave={this.handleMouseOut}
                        >
                          <div className={styles.CopyBtnDiv}>
                            <Button
                              className={styles.copyBtn}
                              data-component="wa_skip_track"
                              onClick={() => {
                                window.wap_tms.aaTrack.do(
                                  "Components",
                                  "click: copy",
                                  packageName +
                                  "-copy-" +
                                  downloadItemList.consumption.en
                                );
                                navigator.clipboard.writeText(
                                  downloadItemList.consumption.en
                                );
                              }}
                            >
                              Copy
                            </Button>
                          </div>
                          <div
                            className={styles.productKeyWrapper}
                            ref={this.consumptionBoxHeight}
                          >
                            <div
                              className={styles.modulesWrapper}
                              style={{
                                width: this.state.consumptionBoxHeight,
                              }}
                              id="pullCommand"
                            >
                              {downloadItemList.consumption.en}
                            </div>
                          </div>
                          <Overlay
                            show={this.state.openConsumptionPopover}
                            placement="top-start"
                            positionLeft={400}
                            data-container="body"
                            target={this.consumptionBoxHeight}
                            containerPadding={20}
                            rootClose={true}
                            transition={false}
                            onHide={() =>
                              this.setState({ openConsumptionPopover: false })
                            }
                            className={styles.hoverPopover}
                          >
                            <Popover
                              id="popover-container"
                              className={styles.popoverOverlay}
                            >
                              <Popover.Body
                                style={{
                                  height: this.state.popoverBodyHeight,
                                }}
                              >
                                <div> {downloadItemList.consumption.en}</div>
                              </Popover.Body>
                            </Popover>
                          </Overlay>
                        </div>
                      )}
                    ):(
                    <div className={styles.OdLicenseAgreementTextDiv}>
                    <div className={styles.subscriptionstyles} style={{paddingTop:'3px', position:'absolute', top:'39px', whiteSpace:'nowrap'}}>
                      Please review and accept the license agreement before proceeding
                    </div>
                    <div className={styles.btnWrapper} style={{padding:'2px 16px 16px 16px'}}>
                      <Button
                        className={styles.downloadBtn}
                        style={{width:'296px'}}
                        onClick={this.licenseAgreementForOnlineDistribution}
                      >
                        Review License Agreement&nbsp;
                      </Button>
                  </div></div>)
                    )}
                </div>
                {this.state.displayIngredients && (
                  <div className={styles.ModulesList}>
                    <EIIncludedComponents />
                  </div>
                )}
                {recipeClassInColapse !== "Online Distribution" &&
                  this.state.selectedDownloadOption !== "customDownload" ? (
                  <div className={styles.SubscribeTextDiv}>
                    <Checkbox
                      id="optInForSubscription"
                      value="optInForSubscription"
                      color="primary"
                      checked={this.state.optInForSubscription}
                      onChange={this.handleOptInSelection}
                      className={styles.SubscribeCheckBox}
                      icon={<img src={checkboxIcon} alt="checkbox" />}
                      checkedIcon={
                        <img src={checkboxIconSelected} alt="checkbox" />
                      }
                    />
                    <span className={styles.subscriptionstyles}>
                      {t("YES_I_WOULD_LIKE_TO")}
                    </span>
                  </div>
                ) : (
                  <div className={styles.SubscribeTextDiv}></div>
                )}
              </div>
            )}
        </div>
        <Overlay
          show={this.state.showInfo}
          placement="top-start"
          data-container="body"
          target={this.state.infoTarget}
          containerPadding={20}
          rootClose={true}
          transition={false}
          onHide={() => this.setState({ showInfo: false })}
        >
          <MyPopover id="popover-selction-info" className={styles.popoverOverlay}>
            <Popover.Header as="div">
              <div>
                <span className={styles.tooltipHeader}>
                  {this.state.selectionTypeLabel}
                </span>
                <CloseIcon
                  className={styles.closeBtn}
                  onClick={this.closeOverlay}
                />
              </div>
            </Popover.Header>
            <Popover.Body>
              {this.state.selectionTypeHelpText}
            </Popover.Body>
          </MyPopover>
        </Overlay>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  EIReducer: state.EIReducer,
});
export default connect(mapStateToProps, {
  UpdateEnsightContent,
  UpdateRecipeClass,
  checkEntitlementAndGenerateUniqueKey,
  UpdateSelectedIngredients,
  UpdateUserSelections,
  HandleLoader,
  UpdateDownloadType,
  checkEntitlementAndGenerateUniqueKeyNoRedirect,
  UpdateGtsBlockerResponse,
  VerifyEntitlement
})(withTranslation()(withRouter(GridSelection)));
