import React, { Component } from 'react';
import error_404 from '../../resources/images/error_404.png'
import classes from './page404.module.css';
import { withTranslation } from 'react-i18next';
class Page404 extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className={classes.pagenotfound}>
                <img src={error_404} alt="error_404" width="75px" height="75px" />
                <div className={classes.marginleft20}>
                    <h1>{t("PAGE_NOT_FOUND")}</h1>
                    <p>{t("WE_CANT_FIND_THE_PAGE_THAT_YOU_ARE_LOOKING_FOR_IT_MIGHT_HAVE_MOVED_OR_BEEN_RETIRED")}.</p>
                    <p>{t("CHECK_OUT_INTELS_EDGE_SOFTWARE_HUB_TO_FIND_A_FULL_RANGE_OF_SOLUTIONS")}.</p>
                    <p>{t("GOTO")} <a href="https://software.intel.com/content/www/us/en/develop/topics/iot/edge-solutions.html" rel="noopener noreferrer">{t("HOME_PAGE")}</a></p>
                </div>
            </div>
        )
    }


}

export default (withTranslation()(Page404))