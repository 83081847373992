import React, { Component } from "react";
import styles from "./LicenseAgreement.module.css";
//import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { RegisterProduct, GetLicenseData, updateOnlineDistroLicenseAcceptedStatus, UpdateNavigatedFromLicenseAgreementPage } from "../../actions/EIAction";
import Loader from "../../components/loader/loader";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import docIcon from "../../resources/images/doc.png";
import {generateBreadcrumb} from "../../components/Common/common";
import { Loading, Button } from '@carbon/react';
import './LicenseAgreement.scss';
import sanitizeHtml from 'sanitize-html';
//import { useSelector } from "react-redux";

let selectedRecipeType = '';

class LicenseAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      wrapperHeight: 504,
      contentAreaHeight: 404
    };
    this.handleAccept = this.handleAccept.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
  }

  componentDidUpdate(prevProps) {
    const hasAChanged =
      this.props.EIReducer.isLoaded !== prevProps.EIReducer.isLoaded;
    if (hasAChanged) {
      if (this.props.EIReducer.isLoaded === true) {
        this.setState({ showLoader: false });
      } else {
        this.setState({ showLoader: true });
      }
    }
  }

  componentDidMount = async () => {
    selectedRecipeType = this.props.EIReducer.recipeClass
    if (this.props.history.action !== "PUSH") {
      let baseURL = this.props.EIReducer.baseURL;
      this.props.history.push(baseURL);
    } else {
      let recipeId = this.props.EIReducer.recipeId;
      const selectedLanguage = this.props.EIReducer.selectedLanguage;
      let serviceCallStatus = await this.props.GetLicenseData(recipeId);
      //let licenseAgreementHeader = 0;
      // if (serviceCallStatus) {
      //   setTimeout(() => {
      //     let ESHWrapperHeight = document.getElementById("ESHWrapper").offsetHeight;
      //     document.getElementById("MainContent").style.minHeight = ESHWrapperHeight;
      //     let wrapperHeight = ESHWrapperHeight - 40 - 40;
      //     let contentAreaHeight = wrapperHeight - 138;
      //     console.log(wrapperHeight)
      //     this.setState({
      //       wrapperHeight: wrapperHeight,
      //       contentAreaHeight: contentAreaHeight
      //     });
      //   }, 200);
      // }        
      if (window.intel && window.intel.breadcrumbFromJSON) {
        let EITitle = "";
        if (this.props.EIReducer.EITitle) {
          EITitle = this.props.EIReducer.EITitle[selectedLanguage];
          if (EITitle === undefined || EITitle === "") {
            EITitle = this.props.EIReducer.EITitle["en"];
          }
        }
      this.generateBreadcrumbFn();
    }
  }
  }

  generateBreadcrumbFn = () => {    
    if (window.intel && window.intel.breadcrumbFromJSON) {
      const selectedLanguage = this.props.EIReducer.selectedLanguage;
      let EITitle = "";
      if (this.props.EIReducer.EITitle) {
        EITitle = this.props.EIReducer.EITitle[selectedLanguage];
        if (EITitle === undefined || EITitle === "") {
          EITitle = this.props.EIReducer.EITitle["en"];
        }
      }
      let data = { 
        EITitle,      
        pageName: "licenseAgreement",
        history: this.props.history,
        homeURL: this.props.EIReducer.baseURL
      }
      generateBreadcrumb(data);        
    }else {
      setTimeout(() => {
        this.generateBreadcrumbFn();
      }, 1000);
      
    }
  }

  handleAccept() {
    let licenseType = this.props.EIReducer.licenceType;
    let isCNDALicenseApproved = this.props.EIReducer.isCNDALicenseApproved;
    let recipeURL = this.props.EIReducer.recipeURL;
    let selectedOSVersion = this.props.EIReducer.selectedOS;
    let recipeClass = this.props.EIReducer.recipeClass
    if (licenseType.toLowerCase() === "cnda" && isCNDALicenseApproved === false && false) {
      this.props.history.push("/downloadrecipe/" + recipeURL);
    } else {
      let data = {};
      data.components = [];
      data.images = [];
      data.helmIds = [];
      let selectedIngredients = this.props.EIReducer.selectedIngredientsXML;
      let selectedRecipe = this.props.EIReducer.selectedRecipeVersion;
      let recipes = this.props.EIReducer.recipes;
      let getIngredients = recipes.findIndex(
        (item) => item.id === this.props.EIReducer.selectedRecipeVersion
      );
      data.isProdKeyRequired = recipes[getIngredients].productKey;
      let ircProductId = this.props.EIReducer.ircProductId;
      data.osId = selectedOSVersion;
      data.selectedFilterTag = this.props.EIReducer.selectedFilterOption.length ? this.props.EIReducer.selectedFilterOption : null;
      data.downloadType = 'default';
      let recipeClass = this.props.EIReducer.recipeClass
      if(recipeClass !== "Online Distribution"){
        for (let i = 0; i < selectedIngredients.length; i++) {
          if (selectedIngredients[i].type === undefined || selectedIngredients[i].type === "ingredient") {
            data.components.push(selectedIngredients[i].id);
          } else if (selectedIngredients[i].type === "container") {
            data.images.push(selectedIngredients[i].id);
          } else if (selectedIngredients[i].type === "helmchart") {
            data.helmIds.push(selectedIngredients[i].id);
          }
        }
      }
      if (this.props.EIReducer.gettingStartedGuide) {
        data.gettingStartedGuide = this.props.EIReducer.gettingStartedGuide;
      }
      data.recipeClass = recipeClass;
      data.recipeURL = recipeURL;
      if (data.recipeClass === "Online Distribution" || selectedIngredients.length) {
        if (selectedRecipe) {
          data.recipeId = selectedRecipe;
          data.ircProductId = ircProductId;
        }
      data.baseURL = this.props.EIReducer.baseURL
        this.props.RegisterProduct(
          data,
          "licenseagreement",
          this.props.history
        ).then()
        .catch(error=>{
          console.log("inside catch",error)
          let baseURL = this.props.EIReducer.baseURL;
          if (this.props.EIReducer.recipeClass === "Online Distribution"){
            this.props.UpdateNavigatedFromLicenseAgreementPage(true)
          }
          setTimeout(()=>this.props.history.push(baseURL))
        });
      }
    }
    window.wap_tms.aaTrack.do('Components', 'click: Accept', this.props.EIReducer.recipeURL + "-accept-agreement");
  }

  handleDecline() {
    window.wap_tms.aaTrack.do('Components', 'click: decline', this.props.EIReducer.recipeURL + "-decline-agreement");
    let baseURL = this.props.EIReducer.baseURL;
    if (this.props.EIReducer.recipeClass === "Online Distribution"){
      this.props.UpdateNavigatedFromLicenseAgreementPage(true)
    }
    setTimeout(()=>this.props.history.push(baseURL))
  }

  render() {
    const { t } = this.props;
    let licenseagreementContent = "";
    if (
      this.props.EIReducer &&
      this.props.EIReducer.recipes[0] &&
      this.props.EIReducer.licensedata
    )
      licenseagreementContent = this.props.EIReducer.licensedata;
    return (
      // <div>
      //   <div id="MainContent">
      //     {this.state.showLoader === false ? (
      //       <Loading />
      //     ) : (
      //       <>
      //         <div id="licenseAgreementHeader" className={styles.licenseAgreementHeader}>
      //           <img src={docIcon} className={styles.DocIcon} alt=" document icon" />
      //           <div className={styles.LicenseTitleText}>
      //             {t("LICENSE_AGREEMENT_TITLE")}
      //           </div>
      //         </div>

      //         <div id="licenseAgreementWrapper" className={styles.licenseAgreementWrapper} style={{ height: this.state.wrapperHeight }}>

      //           <div
      //             className={styles.margin200}
      //             style={{ height: this.state.contentAreaHeight }}
      //             dangerouslySetInnerHTML={{ __html: licenseagreementContent }}
      //           ></div>
      //           <div className={styles.licenceAgreement}>
      //             <div className={styles.divTableRow}>
      //               <div className={styles.divTableCell}>
      //                 <Button
      //                   id="customDownload"
      //                   data-component="wa_skip_track"
      //                   variant="outline-warning"
      //                   className={styles.declineBtn}
      //                   onClick={this.handleDecline}
      //                 >
      //                   {t("DECLINE")}
      //                 </Button>
      //               </div>
      //               <div
      //                 className={
      //                   `${styles.divTableCell} ${styles.AcceptButtonStyles}`
      //                 }
      //               >
      //                 <Button
      //                   id="defaultDownload"
      //                   data-component="wa_skip_track"
      //                   className={styles.acceptBtn}
      //                   onClick={this.handleAccept}
      //                 >
      //                   {t("ACCEPT")}
      //                 </Button>
      //               </div>
      //             </div>
      //           </div>
      //           {/* <div className={styles.marginBottom70}></div> */}
      //           <div className={styles.gradientTop}></div>
      //           {/* <div className={styles.gradientBottom}></div> */}

      //         </div>
      //       </>
      //     )}
      //   </div>
      // </div>

      <>
      {this.state.showLoader === true ? (
            <Loading />
          ) :

          <> 
            <section className="la-body">
              <div className="la-page-section">
                <p className="la-page-title">License Agreement</p>
                <div className="la-page-body">
                  <div className="la-page-body-card-area">
                    <div className="la-page-body-card-area-highlight-border"></div>
                    <div className="la-page-body-card-section">
                      <div className="la-page-body-card-text-content">
                        <div className="la-page-body-card-title">
                        <div className="la-page-body-card-text" dangerouslySetInnerHTML={{
                             __html: sanitizeHtml(
                              licenseagreementContent,
                              {allowedAttributes:{
                                ...sanitizeHtml.defaults.allowedAttributes,
                                "p": ["style"],
                                "span": ["style"],
                                "table": ["style"],
                                "tbody": ["style"],
                                "td": ["style"],
                                'th': ["style"],
                                "tr": ["style"],
                                // "div": ["style"]
                                // "*": ["style"],
                                // "div":[]
                                },
                                parseStyleAttributes: false
                              }
                              ) }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="la-buttons-section">
                <Button kind="secondary" size="lg" className="la-decline-button la-decline-button-content la-decline-button-text" onClick={this.handleDecline}>Decline</Button>
                <Button size="lg" className="la-accept-button la-accept-button-content la-accept-button-text" onClick={this.handleAccept}>Accept</Button>
              </div>
            </section>
          
          
          </> 
      }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  EIReducer: state.EIReducer,
});
export default connect(mapStateToProps, { RegisterProduct, GetLicenseData, UpdateNavigatedFromLicenseAgreementPage })(
  withTranslation()(withRouter(LicenseAgreement))
);
