import React, { Component } from "react";
import { connect } from "react-redux";
import { UpdateEnsightContent } from "../../actions/EIAction";
import styles from "./EdgeInsightDownload.module.css";
// import downloadIcon from './../resources/images/download_icon.png';
import LaunchIcon from "../../resources/images/ic_link.svg";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
//let downloadicon = require("../../resources/images/download_icon.svg");


class EdgeInsightDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayCopyBtn: true,
    };
  }
  displayCopyBtn = () => {
    this.setState({
      displayCopyBtn: true,
    });
  };
  hideCopyBtn = () => {
    this.setState({
      displayCopyBtn: false,
    });
  };
  render() {
    const { t } = this.props;
    let selectedLanguage = this.props.EIReducer.selectedLanguage;
    let recipeName = this.props.EIReducer.EITitle[selectedLanguage] ? this.props.EIReducer.EITitle[selectedLanguage] : this.props.EIReducer.EITitle.en;
    let gettingStartedGuide = this.props.EIReducer.gettingStartedGuide;
    let productKey = this.props.EIReducer.productKey;
    return (
      <div className={styles.EdgeInsidghtDownload}>
        <div className={styles.downloadDesc}>
        {productKey && (
          <>
          <div className={styles.productkey} style={{paddingTop:28}}>{t("PRODUCT_KEY")}</div>
          <div
            className={styles.listItemnew}
            id={styles.ODTextBox}
            name="Component List"
          >
            {this.state.displayCopyBtn && (
              <div className={styles.CopyBtnDiv}>
                <Button
                  className={styles.copyBtn}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      productKey
                    );
                  }}
                >
                  Copy
                </Button>
              </div>
            )}
            <div className={styles.productKeyWrapper}>
              <div className={styles.modulesWrapper} id="productKey">
                {productKey}
              </div>
            </div>
          </div>
          <div className={styles.packageDetailsHeader}>
            <span>
              {t("DURING_INSTALLATION_YOU_WILL_BE_PROMPTED_TO_ENTER_YOUR_PRODUCT_KEY")}
            </span>
          </div>
          </>
          )}          
          <div className={styles.packageDetailsContainer}>
            <div>
              
              <ul>
                <li>
                <div className={styles.getStartedWrapper}>
                    <div className={styles.getStartedWithMain}>
                      {t("GET_STARTED_WITH")} {recipeName}
                    </div>                    
                    <div>{t("ONCE_YOUR_DOWNLOAD_COMPLETE")}</div>
                  </div>
                </li>
                <li>
                  <b>{t("STEP_1")}</b> {t("PREPARE_YOUR_TARGET_SYSTEM_WITH_THE_TARGET_OS")}
                </li>
                <li>
                  <b>{t("STEP_2")}</b> {t("COPY_THE_ZIP_FILE_TO_YOUR_TARGET_SYSTEM")}
                </li>
                <li>
                  <b>{t("STEP_3")}</b> {t("EXTRACT_AND_INSTALL_THE_SOFTWARE_WITHOUT_PIP")}
                </li>
              </ul>
            </div>
            <div>
              <h1 className={styles.downloadInstruction}>
                {t("SEE_THE")}{" "}
                <a href={gettingStartedGuide} target="_blank" rel="noopener noreferrer">
                  {t("GET_STARTED_GUIDE")}
                  <img src={LaunchIcon} alt="Launch icon" className={styles.iconStyle} />
                </a>{" "}
                {t("FOR_DETAILED_INSTRUCTIONS")}
              </h1>
            </div>
            <div className={styles.packageDetailsContent}>
              <p>
                <span className={styles.font20}>{t("SUPPORT")}</span><br />
                <span className={styles.fontclear}>
                {t("INTEL_DEV_CATALOG")}&nbsp;
                  <a href={this.props.EIReducer.supportLink} target="_blank" rel="noopener noreferrer" >
                    {t("CONTACT_OUR_SUPPORT_TEAM")}<img src={LaunchIcon} alt="Launch icon" className={styles.iconStyle} />
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  EIReducer: state.EIReducer,
});
export default connect(mapStateToProps, { UpdateEnsightContent })(withTranslation()(EdgeInsightDownload));
